import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<h1>Product Management and Photographs</h1>
		<p>I have been working in product management in the blockchain industry since 2018. I want to support building public goods on Ethereum.</p>
		<p>Alongside the above, I have been working as a photographer since 2016. First, I became an in-house photographer at a child product manufacturer. In 2022, I started shooting families and kids as an individual photographer.</p>
		<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
			<Image />
		</div>
		<Link to="/page-2/">Go to page 2</Link> <br />
		<Link to="/using-typescript/">Go to "Using TypeScript"</Link>
	</Layout>
);

export default IndexPage;
